import React from 'react';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import { Row, Col, Button } from 'antd';


export default class Gosignup extends React.Component {
    render() {
        // const { isMobile } = this.props;
        return (
            <div id="gosignup" className="is-white-bg">
                <div className="gosignup is-page">
                    <div className="is-fullpage">
                        {/*  playScale="0.2">*/}
                        <div key="gosignup" type="alpha" delay={150}>
                            <div
                                key="text"
                                type="bottom"
                                delay={300}
                            >
                                <Row key="a">
                                    <Row className="is-center">
                                        <Col span={24} className="text-wrapper">
                                            <h3 className="is-text-align-center">Ready to build your smart business?</h3>
                                            <p>Contact us to discovery how NEXPIE IoT Platform can lead your IoT solution growth.</p>
                                            {/* <a
                                                href="https://auth.nexpie.io/signup"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            > */}
                                            <Button size="large" type="danger" shape="circle" icon="arrow-down" className="buttonanimation" />
                                            {/* <Button size="large" shape="round" className="is-text-align-center" type="primary">SIGN UP</Button> */}
                                            {/* </a> */}
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                        </div>
                        {/* </ScrollOverPack> */}
                    </div>
                </div >
            </div >

        );
    }
}