import React from "react";
import { Link } from "react-router-dom";
import iconemail from "../../assets/iconemail.png";
import iconphone from "../../assets/iconphone.png";
import { Col } from "antd";
import {
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  MediumSquareFilled,
} from "@ant-design/icons";

export default class Contact extends React.Component {
  render() {
    return (
      <div id="contact" className="is-blue-bg">
        <div className="contact is-page">
          <div className="is-fullpage">
            <Col xs={24} md={21} lg={10} xl={10} className="card">
              <div className="inner-card">
                <div className="text-wrapper">
                  <h4>NEXPIE Co., Ltd.</h4>
                  <p>
                    บริษัท เน็กซ์พาย จำกัด | ไทยซัมมิท ทาวเวอร์ ชั้น 14, 1768
                    ถนนเพชรบุรีตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร
                    10310
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={21} lg={7} xl={7} className="card">
              <div className="inner-card">
                <div className="text-wrapper">
                  <h4>Contact us</h4>
                  <p>
                    <img
                      src={iconphone}
                      style={{ width: "25px" }}
                      key="z2"
                      alt="phone"
                      className="is-white-color"
                    />{" "}
                    +6661-421-1289
                  </p>

                  <a href="mailto:contact@nexpie.com" style={{ color: "#fff" }}>
                    <p>
                      <img
                        src={iconemail}
                        style={{ width: "25px" }}
                        key="z3"
                        alt="email"
                        className="is-white-color"
                      />{" "}
                      contact@nexpie.com
                    </p>
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={24} md={21} lg={7} xl={7} className="card">
              <div className="inner-card">
                <div className="text-wrapper">
                  <h4>Follow us</h4>
                    <Col xl={4} md={6} lg={4} xs={6}>
                      <Link
                        to={{ pathname: "https://www.facebook.com/nexpie" }}
                        style={{ color: "#fff" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FacebookFilled style={{ fontSize: "32px" }} />
                      </Link>
                    </Col>
                    <Col xl={4} md={6} lg={4} xs={6}>
                      <Link
                        to={{ pathname: "https://twitter.com/nexpie_iot" }}
                        style={{ color: "#fff" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TwitterSquareFilled style={{ fontSize: "32px" }} />
                      </Link>
                    </Col>

                    <Col xl={4} md={6} lg={4} xs={6}>
                      <Link
                        to={{
                          pathname: "https://www.instagram.com/nexpie_iot",
                        }}
                        style={{ color: "#fff" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramFilled style={{ fontSize: "32px" }} />
                      </Link>
                    </Col>
                    <Col xl={4} md={6} lg={4} xs={6}>
                      <Link
                        to={{ pathname: "https://nexpieiot.medium.com/" }}
                        style={{ color: "#fff" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MediumSquareFilled style={{ fontSize: "32px" }} />
                      </Link>
                    </Col>
                </div>
              </div>
            </Col>
            <Col xs={24} md={0} lg={0} xl={0} className="card">
              <br></br>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
