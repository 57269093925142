import React from 'react';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import about from '../../assets/about.png';
import { Row, Col } from 'antd';


export default class About extends React.Component {
    render() {
        // const { isMobile } = this.props;
        return (
            <div id="about" >
                <Col xs={0} md={0} lg={24} xl={24} className="is-white-bg">
                    <div className="about is-page">
                        <div className="is-fullpage">
                            {/*ScrollOverPack  playScale="0.3">*/}
                            <div key="about" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={14}>
                                        <div
                                            key="img"
                                            type="left"
                                            delay={300}
                                            className="text-wrapper is-text-align-left"
                                        >
                                            <img src={about} style={{ width: '90%' }} key="a" alt="about" />
                                        </div>

                                    </Col>
                                    <Col span={10}>
                                        <div
                                            key="text"
                                            type="bottom"
                                            delay={300}
                                            className="text-wrapper is-text-align-left"
                                        >
                                            <div key="a">
                                                <h3>NEXPIE IoT Platform</h3>
                                                <p>NEXPIE IoT คือ แพลตฟอร์มที่ถูกพัฒนาขึ้นเพื่อตอบสนองผู้ใช้งานเชิงพาณิชย์ เช่น ผู้ผลิตอุปกรณ์ IoT, โรงงานอุตสาหกรรม และองค์กรที่พัฒนาสู่ยุค Digital Transformation 4.0 ซึ่งจะช่วยธุรกิจให้มีประสิทธิภาพยิ่งขึ้น ด้วยเทคโนโลยีการเชื่อมต่อทุกสรรพสิ่ง หรือ Internet of Things (IoT)</p>
                                                <p>โดยแพลตฟอร์มจะช่วยให้อุปกรณ์ต่างๆ สามารถสื่อสารกันได้ เกิดการรับ-ส่งข้อมูลระหว่างอุปกรณ์แบบ real-time ทำให้ผู้ใช้งานทราบถึงข้อมูลของอุปกรณ์ ณ เวลานั้นๆ ไม่ว่าผู้ใช้งานจะอยู่ที่ไหนเวลาใดก็ตาม ทั้งยังรองรับการเชื่อมต่อกับอุปกรณ์ IoT ได้จำนวนมหาศาล ทำให้ตอบโจทย์กลุ่มผู้ใช้งานเชิงพาณิชย์ที่มีอุปกรณ์ IoT จำนวนมากอย่างแน่นอน</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={24} lg={0} xl={0} className="is-white-bg">
                    <div className="about is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="about" type="alpha" delay={150}>
                                <Row>
                                    <Col span={24}>
                                        <div
                                            key="text"
                                            type="bottom"
                                            delay={300}
                                            className="text-wrapper"
                                        >
                                            <div key="a" className="text-wrapper is-text-align-left">
                                                <h4>NEXPIE IoT Platform</h4>
                                                <p>NEXPIE IoT คือ แพลตฟอร์มที่ถูกพัฒนาขึ้นเพื่อตอบสนองผู้ใช้งานเชิงพาณิชย์ เช่น ผู้ผลิตอุปกรณ์ IoT, โรงงานอุตสาหกรรม และองค์กรที่พัฒนาสู่ยุค Digital Transformation 4.0 ซึ่งจะช่วยธุรกิจให้มีประสิทธิภาพยิ่งขึ้น ด้วยเทคโนโลยีการเชื่อมต่อทุกสรรพสิ่ง หรือ Internet of Things (IoT)</p>
                                                <p>โดยแพลตฟอร์มจะช่วยให้อุปกรณ์ต่างๆ สามารถสื่อสารกันได้ เกิดการรับ-ส่งข้อมูลระหว่างอุปกรณ์แบบ real-time ทำให้ผู้ใช้งานทราบถึงข้อมูลของอุปกรณ์ ณ เวลานั้นๆ ไม่ว่าผู้ใช้งานจะอยู่ที่ไหนเวลาใดก็ตาม ทั้งยังรองรับการเชื่อมต่อกับอุปกรณ์ IoT ได้จำนวนมหาศาล ทำให้ตอบโจทย์กลุ่มผู้ใช้งานเชิงพาณิชย์ที่มีอุปกรณ์ IoT จำนวนมากอย่างแน่นอน</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <br></br>
                                    <Col span={24}>
                                        <div
                                            key="img"
                                            type="left"
                                            delay={300}
                                            className="text-wrapper is-text-align-center"
                                        >
                                            <img src={about} style={{ width: '90%' }} key="a" alt="about" />
                                            <br></br>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div>
                </Col>
            </div>
        );
    }
}