import React from 'react';
import { Menu, Icon, Row, Col, Button, Drawer } from 'antd';
import logoWhite from '../../assets/NEXPIE-logo-white.png';
import logoBlack from '../../assets/NEXPIE-Name-Icon.png';

const { SubMenu } = Menu;


export default class Header extends React.Component {

    handleClick = e => {
        // console.log('click ', e);
        this.setState({
            current: e.key,
            visible: false,
        });
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    constructor(props) {
        super(props)
        if (window.location.pathname === "/") {
            this.state = {
                headertransparent: true,
                languagetoggle: false,
                current: 'home',
                visible: false,

            }
            window.addEventListener('scroll', this.listenToScroll)
        }
        else {
            this.state = {
                headertransparent: false,
                languagetoggle: false,
                current: 'pricing',
                visible: false,

            }
            window.removeEventListener('scroll', this.listenToScroll)
        }
    }


    componentWillUnmount() {
        if (window.location.pathname === "/") {
            window.removeEventListener('scroll', this.listenToScroll)
        }
    }

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        if (winScroll > 3) {
            this.setState({
                headertransparent: false,
            })
        }
        else {
            this.setState({
                headertransparent: true,
            })
        }
    }

    render() {

        return (
            <span id="header" >
                <Col style={{ position: 'fixed', zIndex: 1, width: "100%" }} className={this.state.headertransparent ? "is-transparent" : "is-darkblue-bg"} md={24} xs={0}>
                    <div className="is-header is-page is-center" >
                        <Row className="is-center" style={{ width: "100%" }} >
                            <Col lg={17} md={17} xs={0}>
                                <Row className="is-center" style={{ width: "100%" }} type="flex" justify="start">
                                    <Col xs={0} md={6} lg={6}>
                                        <a href="/"><img src={logoWhite} style={{ width: "80%" }} alt="logo" /></a>
                                    </Col>
                                    <Col xs={0} md={18} lg={18} className="is-text-align-left" >

                                        <Menu className="is-transparent is-noborder" onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                                            <SubMenu
                                                title={
                                                    <span className="submenu-title-wrapper">
                                                        <Icon type="home" />
                                                        Home
                                                </span>
                                                }
                                                key="sub1"
                                            >

                                                <Menu.Item key="1"><a href="/#about">NEXPIE IoT Platform</a></Menu.Item>
                                                <Menu.Item key="2"><a href="/#whyus">ทำไมต้องเลือก NEXPIE IoT Platform</a></Menu.Item>
                                                <Menu.Item key="0"><a href="/#feature">คุณสมบัติของ NEXPIE IoT Platform</a></Menu.Item>
                                                {/* <Menu.Item key="3"><a href="/#package">ราคา NEXPIE IoT Platform</a></Menu.Item> */}
                                            </SubMenu>
                                            <SubMenu
                                                title={
                                                    <span className="submenu-title-wrapper">
                                                        <Icon type="code" />
                                                        Developer
                                                    </span>
                                                }
                                                key="sub2"
                                            >
                                                <Menu.Item key="4"><a href="https://docs.nexpie.io/getting-started.html" target="_blank" rel="noopener noreferrer">Quick Start</a></Menu.Item>
                                                <Menu.Item key="5"><a href="https://docs-v2.nexpie.io" target="_blank" rel="noopener noreferrer">Documentation</a></Menu.Item>
                                                <Menu.Item key="6"><a href="https://beta-api.nexpie.io/" target="_blank" rel="noopener noreferrer">Device API</a></Menu.Item>
                                                <Menu.Item key="s3"><a href="/flowengine">Flow Engine</a></Menu.Item>
                                            </SubMenu>
                                            {/* <SubMenu
                                                title={
                                                    <span className="submenu-title-wrapper">
                                                        <Icon type="tag" />
                                                        Pricing
                                        </span>
                                                }
                                                key="sub3"
                                            >
                                                <Menu.Item key="7"><a href="/pricing">Package</a></Menu.Item>
                                                <Menu.Item key="8"><a href="https://docs.nexpie.io/usage-calculate.html" target="_blank" rel="noopener noreferrer">Service Usage Calculation</a></Menu.Item>
                                            </SubMenu> */}
                                            <SubMenu
                                                title={
                                                    <span className="submenu-title-wrapper">
                                                        <Icon type="shop" />
                                                Store
                                        </span>
                                                }
                                                key="sub7"
                                            >
                                                <Menu.Item key="18"><a href="/piebox">PIEBOX 1st Edition series</a></Menu.Item>
                                            </SubMenu>
                                        </Menu>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={7} md={7} xs={0}>
                                <Row className="is-center" style={{ width: "100%" }} type="flex" justify="end">
                                    <Col xs={0} lg={11} md={15}>
                                        <Button key="c" size="large" className="is-button-portal" ghost><a href="#contact" rel="noopener noreferrer">CONTACT US</a></Button>                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                </Col>
                <Col id="header" style={{ position: 'fixed', zIndex: 1, width: "100%" }} className={this.state.headertransparent ? "is-transparent" : "is-darkblue-bg"} xs={24} md={0}>
                    <div className="is-header is-page is-center" >
                        <Row className="is-center" style={{ width: "calc(100vw - 48px)" }} >
                            <Col xs={12} md={0} className="is-text-align-left">
                                <a href="/"><img src={logoWhite} style={{ width: "80%" }} alt="logo" /></a>
                            </Col>
                            <Col xs={12} md={0} className="is-text-align-right">
                                <Button icon="menu" size="large" onClick={this.showDrawer} ghost />
                            </Col>
                            <Drawer
                                placement="top"
                                closable={false}
                                onClose={this.onClose}
                                visible={this.state.visible}
                                xs={24}
                                md={0}
                                className="is-drawer-header"
                            >
                                <Row className="is-center" style={{ width: "calc(100vw - 48px)", height: "64px" }} >
                                    <Col xs={12} md={0} className="is-text-align-left">
                                        <a href="/"><img src={logoBlack} style={{ width: "80%" }} alt="logo" /></a>
                                    </Col>
                                    <Col xs={12} md={0} className="is-text-align-right">
                                        <Button icon="close" type="primary" size="large" onClick={this.onClose} />
                                    </Col>
                                </Row>
                                <Menu mode="inline" style={{ width: "100%" }} className="is-transparent is-noborder" onClick={this.handleClick} selectedKeys={[this.state.current]}>

                                    <Menu.ItemGroup title={
                                        <span className="submenu-title-wrapper">
                                            <Icon type="home" />
                                            Home
                                        </span>
                                    }
                                        key="sub4"
                                    >
                                        <Menu.Item key="9"><a href="/#about">NEXPIE IoT Platform</a></Menu.Item>
                                        <Menu.Item key="10"><a href="/#whyus">ทำไมต้องเลือก NEXPIE IoT Platform</a></Menu.Item>
                                        <Menu.Item key="11"><a href="/#feature">คุณสมบัติของ NEXPIE IoT Platform</a></Menu.Item>
                                        <Menu.Item key="12"><a href="/#package">ราคา NEXPIE IoT Platform</a></Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup
                                        title={
                                            <span className="submenu-title-wrapper">
                                                <Icon type="code" />
                                                Developer
                                        </span>
                                        }
                                        key="sub5"
                                    >
                                        <Menu.Item key="13"><a href="https://docs.nexpie.io/getting-started.html" target="_blank" rel="noopener noreferrer">Quick Start</a></Menu.Item>
                                        <Menu.Item key="14"><a href="https://docs.nexpie.io/" target="_blank" rel="noopener noreferrer">Documentation</a></Menu.Item>
                                        <Menu.Item key="15"><a href="https://beta-api.nexpie.io/" target="_blank" rel="noopener noreferrer">Device API</a></Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup
                                        title={
                                            <span className="submenu-title-wrapper">
                                                <Icon type="tag" />
                                                Pricing
                                        </span>
                                        }
                                        key="sub6"
                                    >
                                        <Menu.Item key="16"><a href="/pricing">Package</a></Menu.Item>
                                        <Menu.Item key="17"><a href="https://docs.nexpie.io/usage-calculate.html" target="_blank" rel="noopener noreferrer">Service Usage Calculation</a></Menu.Item>
                                    </Menu.ItemGroup>
                                    <Menu.ItemGroup
                                        title={
                                            <span className="submenu-title-wrapper">
                                                <Icon type="shop" />
                                                Store
                                        </span>
                                        }
                                        key="sub7"
                                    >
                                        <Menu.Item key="18"><a href="/piebox">PIEBOX 1st Edition series</a></Menu.Item>
                                    </Menu.ItemGroup>
                                </Menu>
                            </Drawer>
                        </Row>
                    </div>
                </Col >
            </span>
        );
    }
}