import React, { Component } from "react";
import { Col } from "antd";
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  TwitterIcon,
} from "react-share";
import { Helmet } from "react-helmet";
import { enquireScreen } from "enquire-js";

let isMobile = false;

class Sharesocial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }

  render() {
    const webUrl = "https://homepage-nexpie.web.app";
    const shareUrl = "https://homepage-nexpie.web.app/piebox";
    const title = "PIEBOX (1st Edition series)";
    const description = "IoT Edge Gateway ขนาดเล็กที่พร้อมติดตั้งโปรแกรมสำหรับรองรับการใช้งานเชื่อมต่อกับแพลตฟอร์ม IoT";
    const appId = "444750056723229";
    const imageUrl = "https://homepage-nexpie.web.app/static/media/Device_01.f470a17c.png";
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <link rel="canonical" href={webUrl} />
          <meta property="og:url" content={shareUrl} />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={title}
          />
          <meta
            property="og:description"
            content={description}
          />
          <meta
            property="og:image"
            content={imageUrl}
          />
          <meta name="fb:app_id" content={appId} />
        </Helmet>
        <Col span={2}>
          <FacebookShareButton
            url={shareUrl}
            quote={title}
          >
            <FacebookIcon size={35} round />
          </FacebookShareButton>
        </Col>
        <Col span={2}>
          <TwitterShareButton
            url={shareUrl}
            title={title}
          >
            <TwitterIcon size={35} round />
          </TwitterShareButton>
        </Col>
        <Col span={2}>
          <LineShareButton
            url={shareUrl}
            title={title}
          >
            <LineIcon size={35} round />
          </LineShareButton>
        </Col>
        <Col span={2}>
          <EmailShareButton
            url={shareUrl}
            subject={title}
          >
            <EmailIcon size={35} round />
          </EmailShareButton>
        </Col>
      </>
    );
  }
}
export default Sharesocial;
