import React from 'react';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import monitor from '../../assets/monitor.gif';
import storage from '../../assets/storage.gif';
import control from '../../assets/control.gif';
import trigger from '../../assets/trigger.gif';
import { Row, Col } from 'antd';


export default class Feature extends React.Component {
    render() {
        // const { isMobile } = this.props;
        return (
            <div id="feature">
                <Col className="is-white-bg" xs={0} md={0} lg={0} xl={24} >
                    <div className="feature is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="feature" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">คุณสมบัติของ NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <div
                                    key="text"
                                    type="bottom"
                                    delay={300}
                                >
                                    <Row key="a">
                                        <Col span={6} className="is-right-border card">
                                            <img src={control} style={{ height: '160px' }} className="img-wrapper" alt="control" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การจัดการอุปกรณ์</h4>
                                                <div className="description">สามารถลงทะเบียนอุปกรณ์ได้หลายรูปแบบ ยืนยันตัวตนอุปกรณ์ด้วยระบบ Token สื่อสารระหว่างอุปกรณ์กับอุปกรณ์ หรืออุปกรณ์กับคลาวด์แบบ Real-Time รองรับระบบเน็ตเวิร์คทั้งแบบ LAN, Wifi, 3G, NB-IoT และ LoRaWAN</div>
                                            </div>
                                        </Col>
                                        <Col span={6} className="is-right-border card">

                                            <img src={storage} style={{ height: '160px' }} className="img-wrapper" alt="storage" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การจัดการข้อมูล</h4>
                                                <div className="description">มีระบบจัดเก็บข้อมูลที่หลากหลาย ได้แก่ ข้อมูล Configuration ของอุปกรณ์และเซนเซอร์ ที่สามารถออกแบบเองได้ในรูปแบบ JSON, การประมวลข้อมูลเบื้องต้นก่อนบันทึก (เช่น การแปลงหน่วยข้อมูล) และการเก็บข้อมูลในรูปแบบ Time Series</div>
                                            </div>
                                        </Col>
                                        <Col span={6} className="is-right-border card">
                                            <img src={monitor} style={{ height: '160px' }} className="img-wrapper" alt="monitor" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การแสดงผลข้อมูล</h4>
                                                <div className="description">มี Dashboard สำหรับแสดงข้อมูลและควบคุมสั่งการอุปกรณ์ได้ รวมถึงมีเครื่องมือที่ช่วยจัดการโฟลว์การทำงานของอุปกรณ์ที่สามารถตั้งค่าเองได้</div>
                                            </div>

                                        </Col>
                                        <Col span={6} className="card">
                                            <img src={trigger} style={{ height: '160px' }} className="img-wrapper" alt="trigger" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">ระบบการแจ้งเตือน</h4>
                                                <div className="description">สามารถตั้งค่าการแจ้งเตือนตามเงื่อนไขต่างๆ เพื่อแจ้งเตือนผ่านทางอีเมล แอพพลิเคชันไลน์ หรือเว็บแอพพลิเคชันอื่นๆได้</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
                <Col className="is-white-bg" xs={0} md={24} lg={24} xl={0} >
                    <div className="feature is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="feature" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">คุณสมบัติของ NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <div
                                    key="text"
                                    type="bottom"
                                    delay={300}
                                >
                                    <Row key="a">
                                        <Col span={12} className="is-right-border card is-bottom-border">
                                            <img src={control} style={{ height: '160px' }} className="img-wrapper" alt="control" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การจัดการอุปกรณ์</h4>
                                                <div className="description">สามารถลงทะเบียนอุปกรณ์ได้หลายรูปแบบ ยืนยันตัวตนอุปกรณ์ด้วยระบบ Token สื่อสารระหว่างอุปกรณ์กับอุปกรณ์ หรืออุปกรณ์กับคลาวด์แบบ Real-Time รองรับระบบเน็ตเวิร์คทั้งแบบ LAN, Wifi, 3G, NB-IoT และ LoRaWAN</div>
                                            </div>
                                        </Col>
                                        <Col span={12} className="card is-bottom-border">

                                            <img src={storage} style={{ height: '160px' }} className="img-wrapper" alt="storage" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การจัดการข้อมูล</h4>
                                                <div className="description">มีระบบจัดเก็บข้อมูลที่หลากหลาย ได้แก่ ข้อมูล Configrulation ของอุปกรณ์และเซนเซอร์ ที่สามารถออกแบบเองได้ในรูปแบบ JSON, การประมวลข้อมูลเบื้องต้นก่อนบันทึก (เช่น การแปลงหน่วยข้อมูล) และการเก็บข้อมูลในรูปแบบ Time Series</div>
                                            </div>
                                        </Col>
                                        <Col span={12} className="is-right-border card">
                                            <img src={monitor} style={{ height: '160px' }} className="img-wrapper" alt="monitor" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การแสดงผลข้อมูล</h4>
                                                <div className="description">มี Dashboard สำหรับแสดงข้อมูลและควบคุมสั่งการอุปกรณ์ได้ รวมถึงมีเครื่องมือที่ช่วยจัดการโฟลว์การทำงานของอุปกรณ์ที่สามารถตั้งค่าเองได้</div>
                                            </div>

                                        </Col>
                                        <Col span={12} className="card">
                                            <img src={trigger} style={{ height: '160px' }} className="img-wrapper" alt="trigger" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">ระบบการแจ้งเตือน</h4>
                                                <div className="description">สามารถตั้งค่าการแจ้งเตือนตามเงื่อนไขต่างๆ เพื่อแจ้งเตือนผ่านทางอีเมล แอพพลิเคชันไลน์ หรือเว็บแอพพลิเคชันอื่นๆได้</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
                <Col className="is-white-bg" xs={24} md={0} lg={0} xl={0} >
                    <div className="feature is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="feature" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">คุณสมบัติของ NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <div
                                    key="text"
                                    type="bottom"
                                    delay={300}
                                >
                                    <Row key="a">
                                        <Col span={24} className="card">
                                            <img src={control} style={{ height: '160px' }} className="img-wrapper" alt="control" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การจัดการอุปกรณ์</h4>
                                                <div className="description no-min-height">สามารถลงทะเบียนอุปกรณ์ได้หลายรูปแบบ ยืนยันตัวตนอุปกรณ์ด้วยระบบ Token สื่อสารระหว่างอุปกรณ์กับอุปกรณ์ หรืออุปกรณ์กับคลาวด์แบบ Real-Time รองรับระบบเน็ตเวิร์คทั้งแบบ LAN, Wifi, 3G, NB-IoT และ LoRaWAN</div>
                                            </div>
                                        </Col>
                                        <Col span={24} className="card">

                                            <img src={storage} style={{ height: '160px' }} className="img-wrapper" alt="storage" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การจัดการข้อมูล</h4>
                                                <div className="description no-min-height">มีระบบจัดเก็บข้อมูลที่หลากหลาย ได้แก่ ข้อมูล Configrulation ของอุปกรณ์และเซนเซอร์ ที่สามารถออกแบบเองได้ในรูปแบบ JSON, การประมวลข้อมูลเบื้องต้นก่อนบันทึก (เช่น การแปลงหน่วยข้อมูล) และการเก็บข้อมูลในรูปแบบ Time Series</div>
                                            </div>
                                        </Col>
                                        <Col span={24} className=" card">
                                            <img src={monitor} style={{ height: '160px' }} className="img-wrapper" alt="monitor" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">การแสดงผลข้อมูล</h4>
                                                <div className="description no-min-height">มี Dashboard สำหรับแสดงข้อมูลและควบคุมสั่งการอุปกรณ์ได้ รวมถึงมีเครื่องมือที่ช่วยจัดการโฟลว์การทำงานของอุปกรณ์ที่สามารถตั้งค่าเองได้</div>
                                            </div>

                                        </Col>
                                        <Col span={24} className="card">
                                            <img src={trigger} style={{ height: '160px' }} className="img-wrapper" alt="trigger" />
                                            <br></br>
                                            <br></br>
                                            <div className="text-wrapper">
                                                <h4 className="is-text-align-center">ระบบการแจ้งเตือน</h4>
                                                <div className="description no-min-height">สามารถตั้งค่าการแจ้งเตือนตามเงื่อนไขต่างๆ เพื่อแจ้งเตือนผ่านทางอีเมล แอพพลิเคชันไลน์ หรือเว็บแอพพลิเคชันอื่นๆได้</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
            </div>

        );
    }
}