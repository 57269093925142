import React from "react";
import Header from "./utils/Header";
import Footer from "./utils/Footer";
import { Icon, Layout, Button, notification } from 'antd';
import Cookies from '../utils/cookie';
import dayjs from 'dayjs';

const Page = ({ children, isMobile }) => {

    const onAccept = () => {
		//set cookies
		const expireDate = new Date(dayjs().add(1, 'month'))
		Cookies.set('accept_alert_cookies_date', true, {expires: expireDate});
    notification.close("alert")
	};

    const btn = (
        <Button type="primary" onClick={() => onAccept()} >
          Accept
        </Button>
    );

    const closeIcon = (
        <Icon type="close" style={{ color: "gray"}} />
    );

    const openNotification = () => {
        const w = window.innerWidth - 50
        notification.open({
          placement: 'bottomLeft',
          duration: 0,
          description:
            <><span>This website uses cookies to offer you a seamless experience. These cookies are essential for running our website and are key to providing you a smoother and more personalized experience. By using our website, you acknowledge that you have read and understand our cookie policy.</span> <a href="https://nexpie.com/privacypolicy.pdf">Privacy policy</a></>,
          btn,
          style: {
            width: w +'px',
            marginBottom: '0px',
            backgroundColor: '#000',
            color: '#fff',
            opacity: 0.9,
            fontSize: '12px',
            padding: '15px'
          },
          key: "alert",
          closeIcon
        });
        // notification.open(<AlertPolicy/>)
    };

    return (
        <Layout >
            <Header isMobile={isMobile} />
            {!Cookies.get('accept_alert_cookies_date') && openNotification()}
            {children}
            <Footer isMobile={isMobile} />
        </Layout>
    );
};

export default Page;
