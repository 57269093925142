import React, { Component } from 'react';
import Contact from '../components/utils/Contact';
import { enquireScreen } from 'enquire-js';
import Page from "../components/Page";
import Backtotop from '../components/utils/Backtotop';
import { Row, Col } from 'antd';


let isMobile = false;
enquireScreen((b) => {
    isMobile = b;
});
class Redeemtermofuse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b,
            });
        });
    }

    render() {
        return (
            <>
                <Page>
                    <div id="redeemtermofuse" className="is-white-bg">
                        <div className="redeemtermofuse is-page" >
                            <div className="is-fullpage ">
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a"> NEXPIE Redeem Code Term and Condition</h3>
                                        <p>This Term and Condition last updated on February 20, 2020</p>

                                        <ul>
                                            <li><p>This Redeem Code is used for the redeemed NEXPIE IoT Platform for NEXPIE services only.
                                            </p></li>
                                            <li><p>This Redeem Code is valid until 180 days from the date of purchase, and can be redeemed at <a href="https://portal.nexpie.io/user/billing">https://portal.nexpie.io/user/billing</a> only.
                                            </p></li>
                                            <li><p>This Redeem Code is non-refundable and cannot be exchanged for cash in part or full and is valid for a single transaction only.
                                            </p></li>
                                            <li><p>Strictly no extension of the expiry date from the date of issue will be allowed.
                                            </p></li>
                                            <li><p>The provider retains the right to reject any Redeem Code that has been tampered with or found in any way unacceptable.
                                            </p></li>
                                            <li><p>We are not responsible if a Redeem Code is lost, stolen, damaged or destroyed and no replacement will be provided in these circumstances.
                                            </p></li>
                                            <li><p>Redeem Code cannot be clubbed and used for a single transaction.
</p></li>
                                        </ul>
                                        <p>NEXPIE reserves the right to change any of these terms and conditions; notice of any such change will be made by updating the NEXPIE Redeem Code terms and conditions located at <a href="/redeem-term">https://nexpie.io/redeem-term</a>
                                        </p>
                                        <p>NEXPIE Redeem Code is issued and supplied by NEXPIE Company Limited.</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Contact isMobile={this.state.isMobile} />
                    <Backtotop />
                </Page>
            </>
        );
    }
}
export default Redeemtermofuse;