import React, { Component } from "react";
import Contact from "../components/utils/Contact";
import Sharesocial from "../components/utils/Sharesocial";
import { enquireScreen } from "enquire-js";
import Page from "../components/Page";
import piebox1 from "../assets/Device_01.png";
import piebox2 from "../assets/Device_02.png";
import piebox3 from "../assets/Package_Model_01.jpg";
import piebox4 from "../assets/Package_Model_02.jpg";
import iconpiebox from "../assets/iconPIEBOX.png";
import usbcable from "../assets/usbcable.png";
import antenna from "../assets/antenna.png";
import cortex from "../assets/cortex.png";
import RS485 from "../assets/RS485.png";
import storage from "../assets/storage.png";
import network from "../assets/network.png";
import usbhost from "../assets/usbhost.png";
import memory from "../assets/memory.png";
import alert from "../assets/alert.png";
import dashboard from "../assets/dashboard.png";
import modbus from "../assets/modbus.png";
import ruleengine from "../assets/ruleengine.png";
import iconemail from "../assets/iconemail.png";
import iconphone from "../assets/iconphone.png";
import nexpie from "../assets/NEXPIE-Name-Icon.png";
import netpie from "../assets/netpie-logo.png";
import { Col, Row } from "antd";
import { Button } from "antd";

let isMobile = false;
let isSelectImg = piebox1;

enquireScreen((b) => {
  isMobile = b;
});
class PIEBOX1steditionseries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      isSelectImg,
    };
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }

  render() {
    const selectImg = (id) => {
      this.setState({
        isSelectImg: id,
      });
    };

    return (
      <>
        <Page>
          <div id="piebox1steditionseries" className="is-white-bg">
            <div className="product is-page">
              <div className="is-fullpage ">
                <Col xs={24} md={12} lg={12} xl={12} className="text-wrapper">
                  <Col
                    xs={0}
                    md={4}
                    lg={4}
                    xl={4}
                    className="text-wrapper"
                  ></Col>
                  <Col xs={24} md={19} lg={19} xl={19} className="text-wrapper">
                    <Col span={24} className="text-wrapper">
                      <div
                        className="img-border"
                        style={{ marginBottom: "11px" }}
                      >
                        <br></br>
                        <div
                          className="img-wrapper is-text-align-center"
                          key="b"
                          type="right"
                          delay={300}
                        >
                          <img
                            src={this.state.isSelectImg}
                            style={{ width: "65%" }}
                            key="a"
                            alt="world"
                          />
                        </div>
                        <br></br>
                      </div>
                      <Col span={6} className="text-wrapper">
                        <div
                          className="img-border"
                          style={{ marginRight: "10px" }}
                          onMouseEnter={() => selectImg(piebox1)}
                        >
                          <br></br>
                          <div
                            className="img-wrapper is-text-align-center"
                            key="b"
                            type="right"
                            delay={300}
                          >
                            <img
                              src={piebox1}
                              style={{ width: "calc(60%)" }}
                              key="a"
                              alt="world"
                            />
                          </div>
                          <br></br>
                        </div>
                      </Col>
                      <Col span={6} className="text-wrapper">
                        <div
                          className="img-border"
                          style={{ marginRight: "6.5px", marginLeft: "3.5px" }}
                          onMouseEnter={() => selectImg(piebox2)}
                        >
                          <br></br>
                          <div
                            className="img-wrapper is-text-align-center"
                            key="b"
                            type="right"
                            delay={300}
                          >
                            <img
                              src={piebox2}
                              style={{ width: "calc(60%)" }}
                              key="a"
                              alt="world"
                            />
                          </div>
                          <br></br>
                        </div>
                      </Col>
                      <Col span={6} className="text-wrapper">
                        <div
                          className="img-border"
                          style={{ marginRight: "3.5px", marginLeft: "6.5px" }}
                          onMouseEnter={() => selectImg(piebox3)}
                        >
                          <br></br>
                          <div
                            className="img-wrapper is-text-align-center"
                            key="b"
                            type="right"
                            delay={300}
                          >
                            <img
                              src={piebox3}
                              style={{ width: "calc(60%)" }}
                              key="a"
                              alt="world"
                            />
                          </div>
                          <br></br>
                        </div>
                      </Col>
                      <Col span={6} className="text-wrapper">
                        <div
                          className="img-border"
                          style={{ marginLeft: "10px" }}
                          onMouseEnter={() => selectImg(piebox4)}
                        >
                          <br></br>
                          <div
                            className="img-wrapper is-text-align-center"
                            key="b"
                            type="right"
                            delay={300}
                          >
                            <img
                              src={piebox4}
                              style={{ width: "calc(60%)" }}
                              key="a"
                              alt="world"
                            />
                          </div>
                          <br></br>
                        </div>
                      </Col>
                    </Col>
                  </Col>
                  <Col
                    xs={0}
                    md={1}
                    lg={1}
                    xl={1}
                    className="text-wrapper"
                  ></Col>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} className="text-wrapper">
                  <Col span={24}>
                    <Col
                      xs={0}
                      md={1}
                      lg={1}
                      xl={1}
                      className="text-wrapper"
                    ></Col>
                    <Col
                      xs={24}
                      md={19}
                      lg={19}
                      xl={19}
                      className="text-wrapper"
                    >
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={0}
                        className="text-wrapper"
                      >
                        <br></br>
                        <h3 key="a">PIEBOX</h3>
                        <h3 key="a">(1st Edition series)</h3>
                        <h4 key="b" style={{ color: "#d32026" }}>
                          ฿3,990
                        </h4>
                        <h4 key="b" style={{ color: "#d32026" }}>
                          ฟรี NEXPIE IoT Platform 1 ปี
                        </h4>
                        <hr
                          style={{
                            color: "#f0f0f0",
                            backgroundColor: "#f0f0f0",
                            borderWidth: 0,
                            height: 1,
                          }}
                        ></hr>
                      </Col>
                      <Col
                        xs={0}
                        md={0}
                        lg={0}
                        xl={24}
                        className="text-wrapper"
                      >
                        <h3 key="a">PIEBOX (1st Edition series)</h3>
                        <h4 key="b" style={{ color: "#d32026" }}>
                          ฿3,990 ฟรี NEXPIE IoT Platform 1 ปี
                        </h4>
                        <hr
                          style={{
                            color: "#f0f0f0",
                            backgroundColor: "#f0f0f0",
                            borderWidth: 0,
                            height: 1,
                          }}
                        ></hr>
                      </Col>
                      <br></br>
                    </Col>
                    <Col
                      xs={0}
                      md={4}
                      lg={4}
                      xl={4}
                      className="text-wrapper"
                    ></Col>
                  </Col>
                  <Col xs={0} md={0} lg={24} xl={24}>
                    <Row>
                      <Col span={1} className="text-wrapper"></Col>
                      <Col span={19} className="text-wrapper">
                        <Sharesocial></Sharesocial>
                      </Col>
                      <Col span={4} className="text-wrapper"></Col>
                    </Row>
                  </Col>
                  <Col xs={0} md={0} lg={24} xl={24}>
                    <Col span={1} className="text-wrapper"></Col>
                    <Col span={19} className="text-wrapper">
                      <h4>Overview</h4>
                      <p>
                        IoT Edge Gateway
                        ขนาดเล็กที่พร้อมติดตั้งโปรแกรมสำหรับรองรับการใช้งานเชื่อมต่อกับแพลตฟอร์ม
                        IoT
                      </p>
                      <Col span={24} className="text-wrapper">
                        <br></br>
                        <Col span={6} className="is-text-align-center">
                          <div>
                            <img
                              src={dashboard}
                              style={{ width: "32px" }}
                              key="m"
                              alt="Dashboard"
                            />
                            <p>Dashboard</p>
                          </div>
                        </Col>
                        <Col span={6} className="is-text-align-center">
                          <img
                            src={alert}
                            style={{ width: "32px" }}
                            key="n"
                            alt="Alert"
                          />
                          <p>Alert</p>
                        </Col>
                        <Col span={6} className="is-text-align-center">
                          <img
                            src={ruleengine}
                            style={{ width: "32px" }}
                            key="o"
                            alt="ruleengine"
                          />
                          <p>Rule Engine</p>
                        </Col>
                        <Col span={6} className="is-text-align-center">
                          <img
                            src={modbus}
                            style={{ width: "32px" }}
                            key="p"
                            alt="modbus"
                          />
                          <p>Modbus</p>
                        </Col>
                      </Col>
                      <Col span={24} className="text-wrapper">
                        <br></br>
                        <h4>Documentation</h4>
                        <Button
                          className="color-button-primary"
                          type="primary"
                          icon="file-text"
                          href="https://archive.nexpie.io/piebox/manuals/piebox_flowengine_20200902.pdf"
                          target="_blank"
                        >
                          DOCUMENT
                        </Button>{" "}
                      </Col>
                    </Col>
                    <Col span={4} className="text-wrapper"></Col>
                  </Col>
                </Col>
                <Col span={24} className="text-wrapper">
                  <Col
                    xs={0}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-wrapper"
                  ></Col>
                  <Col xs={24} md={20} lg={20} xl={20} className="text-wrapper">
                    <br></br>
                    <Col xs={0} md={24} lg={24} xl={24}>
                      <br></br>
                    </Col>
                    <Col xs={24} md={24} lg={0} xl={0}>
                      <Row>
                        <Col
                          xs={24}
                          md={20}
                          lg={20}
                          xl={20}
                          className="text-wrapper"
                        >
                          <Sharesocial></Sharesocial>
                        </Col>
                        <br></br>
                      </Row>
                    </Col>
                    <Col xs={24} md={24} lg={0} xl={0}>
                      <Col
                        xs={24}
                        md={20}
                        lg={20}
                        xl={20}
                        className="text-wrapper"
                      >
                        <h4>Overview</h4>
                        <p>
                          IoT Edge Gateway
                          ขนาดเล็กที่พร้อมติดตั้งโปรแกรมสำหรับรองรับการใช้งานเชื่อมต่อกับแพลตฟอร์ม
                          IoT
                        </p>
                        <Col span={24} className="text-wrapper">
                          <br></br>
                          <Col span={6} className="is-text-align-center">
                            <img
                              src={dashboard}
                              style={{ width: "32px" }}
                              key="m"
                              alt="Dashboard"
                            />
                            <p>Dashboard</p>
                            <br></br>
                          </Col>
                          <Col span={6} className="is-text-align-center">
                            <img
                              src={alert}
                              style={{ width: "32px" }}
                              key="n"
                              alt="Alert"
                            />
                            <p>Alert</p>
                            <br></br>
                          </Col>
                          <Col span={6} className="is-text-align-center">
                            <img
                              src={ruleengine}
                              style={{ width: "32px" }}
                              key="o"
                              alt="ruleengine"
                            />
                            <p>Rule Engine</p>
                            <br></br>
                          </Col>
                          <Col span={6} className="is-text-align-center">
                            <img
                              src={modbus}
                              style={{ width: "32px" }}
                              key="p"
                              alt="modbus"
                            />
                            <p>Modbus</p>
                            <br></br>
                          </Col>
                        </Col>
                        <br></br>
                        <Col span={24} className="text-wrapper">
                          <h4>Documentation</h4>
                          <Button
                            className="color-button-primary"
                            type="primary"
                            icon="file-text"
                            href="https://archive.nexpie.io/piebox/manuals/piebox_flowengine_20200902.pdf"
                            target="_blank"
                          >
                            DOCUMENT
                          </Button>
                          <br></br>
                          <br></br>
                          <br></br>
                        </Col>
                      </Col>
                      <br></br>
                    </Col>
                    <h4>PIEBOX</h4>
                    <p>
                      IoT Gateway ขนาดเล็ก
                      ซึ่งจะเข้ามาช่วยให้การเชื่อมต่อระหว่างอุปกรณ์หรือเซนเซอร์ต่าง
                      ๆ กับ IoT Platform ทำได้ง่ายและสะดวกสบายยิ่งขึ้น
                      ด้วยซอฟต์แวร์ <b>Flow Engine</b>{" "}
                      ที่ติดตั้งมาพร้อมใช้งานในตัว
                      และรองรับการเชื่อมต่อโปรโตคอลได้หลากหลาย เช่น Modbus,
                      HTTP, MQTT, Serial Port เป็นต้น
                    </p>
                    <br></br>
                    <h4>Flow Engine</h4>
                    <p>
                      ซอฟต์แวร์ที่ติดตั้งใน PIEBOX ช่วยให้การปรับแต่งระบบ IoT
                      ที่พัฒนาสะดวกยิ่งขึ้น
                      การใช้งานจะเป็นลักษณะของการเชื่อมต่อโหนดการทำงานแต่ละส่วนเข้าด้วยกัน
                      เรียกว่า Flow Programming
                      โดยฟังก์ชันการทำงานจะถูกบรรจุอยู่ในโหนดที่สามารถลากเชื่อมต่อกันได้
                      และทำงานเหมือนกับแผนภูมิการไหล (Flow Chart) คือ
                      จะเริ่มทำงานจากโหนดต้นทาง
                      และส่งค่าข้อมูลที่ประมวลผลต่อให้กับโหนดถัดไปที่ถูกเชื่อมโยงกันจากโหนดซ้ายไปโหนขวา
                      นอกจากนี้ ยังสามารถสร้าง Local Dashboard ได้ในตัว
                    </p>
                    <br></br>
                    <h4>Feature</h4>
                    <ul>
                      <li>
                        <p>
                          พร้อมเชื่อมต่อไปยังแพลตฟอร์ม{" "}
                          <a
                            href="https://nexpie.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={nexpie}
                              style={{ height: "14px" }}
                              key="nx"
                              alt="nexpie"
                            />
                          </a>{" "}
                          และ{" "}
                          <a
                            href="https://netpie.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={netpie}
                              style={{ height: "30px" }}
                              key="np"
                              alt="netpie"
                            />
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          รองรับโปรโตคอล Modbus, HTTP, MQTT และการเชื่อมต่อ
                          Serial Port
                        </p>
                      </li>
                      <li>
                        <p>
                          พร้อมเชื่อมต่อทุกอุปกรณ์ด้วยพอร์ต USB, WAN และ LAN
                        </p>
                      </li>
                      <li>
                        <p>
                          รองรับระบบเครือข่ายด้วย Ethernet และ Bluetooth 4.0
                        </p>
                      </li>
                      <li>
                        <p>ติดตั้งซอฟแวร์ Flow Engine พร้อมสำหรับการใช้งาน</p>
                      </li>
                      <li>
                        <p>
                          ตั้งค่าทุกอุปกรณ์ได้ง่าย เพียง "ลาก-วาง Flow ของ
                          ฟังก์ชัน" ด้วย Flow Engine
                        </p>
                      </li>
                    </ul>
                    <br></br>
                    <h4>Specification</h4>
                    <ul>
                      <li>
                        <p>
                          <img
                            src={cortex}
                            style={{ width: "32px" }}
                            key="r"
                            alt="cortex"
                          />{" "}
                          Cortex-A7 Quad-core 1.2GHz
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={memory}
                            style={{ width: "32px" }}
                            key="s"
                            alt="memory"
                          />{" "}
                          Memory : DDR3 RAM 1 GB
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={storage}
                            style={{ width: "32px" }}
                            key="t"
                            alt="iconpiebox"
                          />{" "}
                          Storage : eMMC 8GB, SD card slot
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={network}
                            style={{ width: "32px" }}
                            key="u"
                            alt="iconpiebox"
                          />{" "}
                          Network : 2 x Ethernet, WiFi, Bluetooth 4.0
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={usbhost}
                            style={{ width: "32px" }}
                            key="v"
                            alt="iconpiebox"
                          />{" "}
                          USB Host : 2x Type-A
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={RS485}
                            style={{ width: "32px" }}
                            key="w"
                            alt="iconpiebox"
                          />{" "}
                          Optional RS-485
                        </p>
                      </li>
                    </ul>
                    <br></br>
                    <h4>Include</h4>
                    <ul>
                      <li>
                        <p>
                          <img
                            src={iconpiebox}
                            style={{ width: "32px" }}
                            key="x"
                            alt="iconpiebox"
                          />{" "}
                          1 x PIEBOX
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={antenna}
                            style={{ width: "32px" }}
                            key="y"
                            alt="antenna"
                          />{" "}
                          1 x Antenna
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={usbcable}
                            style={{ width: "32px" }}
                            key="z"
                            alt="usbcable"
                          />{" "}
                          1 x Micro USB cable
                        </p>
                      </li>
                    </ul>
                    <br></br>
                    <h4>พิเศษ</h4>
                    <h4 style={{ color: "#d32026" }}>
                      ซื้อ PIEBOX ได้แล้ววันนี้ ในราคาเริ่มต้นเพียง 3,990 บาท
                    </h4>
                    <h4 style={{ color: "#d32026" }}>
                      พร้อมรับสิทธิพิเศษในการใช้งาน NEXPIE IoT Platform ฟรี 1 ปี
                    </h4>
                    <p>**ราคายังไม่รวมภาษีมูลค่าเพิ่ม VAT 7%</p>
                    <p>
                      <b>สนใจสั่งซื้อสินค้าติดต่อได้ที่</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <img
                            src={iconphone}
                            style={{ width: "32px" }}
                            key="z1"
                            alt="phone"
                          />{" "}
                          061-421-1289, 063-206-3398
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={iconemail}
                            style={{ width: "32px" }}
                            key="z3"
                            alt="email"
                          />{" "}
                          contact@nexpie.com
                        </p>
                      </li>
                    </ul>
                    <br></br>
                    <br></br>
                  </Col>
                  <Col
                    xs={0}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-wrapper"
                  ></Col>
                </Col>
              </div>
            </div>
          </div>
          <Contact></Contact>
        </Page>
      </>
    );
  }
}
export default PIEBOX1steditionseries;
