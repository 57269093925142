import React, { Component } from 'react';
import Banner from '../components/home/Banner';
import About from '../components/home/About';
import Whyus from '../components/home/Whyus';
import Feature from '../components/home/Feature';
import Package from '../components/pricing/Package';
import Gosignup from '../components/home/Gosignup';
import Contact from '../components/utils/Contact';
import Backtotop from '../components/utils/Backtotop';
import { enquireScreen } from 'enquire-js';
import Page from "../components/Page";

let isMobile = false;
enquireScreen((b) => {
    // console.log(b)
    isMobile = b;
});
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
    }

    render() {
        return (
            <>
                <Page>
                    <Banner isMobile={this.state.isMobile} />
                    <About isMobile={this.state.isMobile} />
                    <Whyus isMobile={this.state.isMobile} />
                    <Feature isMobile={this.state.isMobile} />
                    {/* <Package isMobile={this.state.isMobile} /> */}
                    <Gosignup isMobile={this.state.isMobile} />
                    <Contact isMobile={this.state.isMobile} />
                    <Backtotop />
                </Page>
            </>
        );
    }
}
export default Home;