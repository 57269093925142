import React from 'react';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import flexibility from '../../assets/flexibility.gif';
import datavisualization from '../../assets/datavisualization.gif';
import integration from '../../assets/integration.gif';
import protocol from '../../assets/protocol.gif';
import { Row, Col } from 'antd';


export default class Whyus extends React.Component {
    render() {
        // const { isMobile } = this.props;
        return (
            <div id="whyus" >
                <Col className="is-lightblue-bg" xs={0} md={0} lg={0} xl={24} >
                    <div className="whyus is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="whyus" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">ทำไมต้องเลือก NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <div
                                    key="text"
                                    type="bottom"
                                    delay={300}
                                >
                                    <Row key="a">
                                        <Col span={6} className="card">
                                            <div className="inner-card">
                                                <img src={flexibility} style={{ height: '160px' }} className="img-wrapper" alt="flexibility" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">ความยืดหยุ่นสูง</h4>
                                                    <div className="description">ด้วยสถาปัตยกรรมแบบ Microservices ที่ทำงานร่วมกันอย่างอิสระ พร้อมรองรับการปรับแต่ง การเพิ่ม/ลดทรัพยากรที่ใช้แบบอัตโนมัติ (autoscaling) โดยใช้ Kubernetes ในจัดการและควบคุมการทำงานของทุก Microservices</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={6} className="card">
                                            <div className="inner-card">

                                                <img src={datavisualization} style={{ height: '160px' }} className="img-wrapper" alt="datavisualization" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">แพลตฟอร์มกลาง/ส่วนตัว</h4>
                                                    <div className="description">สามารถเลือกใช้บริการได้ทั้งแบบแพลตฟอร์มกลาง (Public Platform) แชร์ทรัพยากรร่วมกับผู้อื่น หรือแพลตฟอร์มส่วนตัว (Private Platform) สำหรับการใช้งานภายในองค์กร</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col span={6} className="card">
                                            <div className="inner-card">

                                                <img src={protocol} style={{ height: '160px' }} className="img-wrapper" alt="protocol" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">รองรับอุปกรณ์ที่หลากหลาย</h4>
                                                    <div className="description">จากการออกแบบให้เชื่อมต่อแพลตฟอร์มตามมาตรฐานของ MQTT Protocol ทำให้รองรับอุปกรณ์ตั้งแต่ Microcontroller ขนาดเล็กไปจนถึง Embedded Linux และ Server และรองรับทุกโปรแกรมภาษา</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col span={6} className="card">
                                            <div className="inner-card">

                                                <img src={integration} style={{ height: '160px' }} className="img-wrapper" alt="integration" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">พร้อมเชื่อมต่อระบบที่มีอยู่</h4>
                                                    <div className="description">รองรับการเชื่อมต่อไปยังระบบอื่นๆ ที่มีอยู่เดิม (3rd Party) ด้วย APIs ที่ถูกออกแบบไว้สำหรับหลากหลายรูปแบบ</div>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
                <Col className="is-lightblue-bg" xs={0} md={24} lg={24} xl={0} >
                    <div className="whyus is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="whyus" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">ทำไมต้องเลือก NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <div
                                    key="text"
                                    type="bottom"
                                    delay={300}
                                >
                                    <Row key="a">
                                        <Col span={12} className="card">
                                            <div className="inner-card">
                                                <img src={flexibility} style={{ height: '160px' }} className="img-wrapper" alt="flexibility" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">ความยืดหยุ่นสูง</h4>
                                                    <div className="description">ด้วยสถาปัตยกรรมแบบ Microservices ที่ทำงานร่วมกันอย่างอิสระ พร้อมรองรับการปรับแต่ง การเพิ่ม/ลดทรัพยากรที่ใช้แบบอัตโนมัติ (autoscaling) โดยใช้ Kubernetes ในจัดการและควบคุมการทำงานของทุก Microservices</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12} className="card">
                                            <div className="inner-card">
                                                <img src={datavisualization} style={{ height: '160px' }} className="img-wrapper" alt="datavisualization" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">แพลตฟอร์มกลาง/ส่วนตัว</h4>
                                                    <div className="description">สามารถเลือกใช้บริการได้ทั้งแบบแพลตฟอร์มกลาง (Public Platform) แชร์ทรัพยากรร่วมกับผู้อื่น หรือแพลตฟอร์มส่วนตัว (Private Platform) สำหรับการใช้งานภายในองค์กร</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col span={12} className="card">
                                            <div className="inner-card">

                                                <img src={integration} style={{ height: '160px' }} className="img-wrapper" alt="integration" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">รองรับอุปกรณ์ที่หลากหลาย</h4>
                                                    <div className="description">จากการออกแบบให้เชื่อมต่อแพลตฟอร์มตามมาตรฐานของ MQTT Protocol ทำให้รองรับอุปกรณ์ตั้งแต่ Microcontroller ขนาดเล็กไปจนถึง Embedded Linux และ Server และรองรับทุกโปรแกรมภาษา</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col span={12} className="card">
                                            <div className="inner-card">

                                                <img src={integration} style={{ height: '160px' }} className="img-wrapper" alt="integration" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">พร้อมเชื่อมต่อระบบที่มีอยู่</h4>
                                                    <div className="description">รองรับการเชื่อมต่อไปยังระบบอื่นๆ ที่มีอยู่เดิม (3rd Party) ด้วย APIs ที่ถูกออกแบบไว้สำหรับหลากหลายรูปแบบ</div>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
                <Col className="is-lightblue-bg" xs={24} md={0} lg={0} xl={0} >
                    <div className="whyus is-page">
                        <div className="is-fullpage">
                            {/*  playScale="0.3">*/}
                            <div key="whyus" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">ทำไมต้องเลือก NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <div
                                    key="text"
                                    type="bottom"
                                    delay={300}
                                >
                                    <Row key="a">
                                        <Col span={24} className="card">
                                            <div className="inner-card">
                                                <img src={flexibility} style={{ height: '160px' }} className="img-wrapper" alt="flexibility" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">ความยืดหยุ่นสูง</h4>
                                                    <div className="description">ด้วยสถาปัตยกรรมแบบ Microservices ที่ทำงานร่วมกันอย่างอิสระ พร้อมรองรับการปรับแต่ง การเพิ่ม/ลดทรัพยากรที่ใช้แบบอัตโนมัติ (autoscaling) โดยใช้ Kubernetes ในจัดการและควบคุมการทำงานของทุก Microservices</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={24} className="card">
                                            <div className="inner-card">
                                                <img src={datavisualization} style={{ height: '160px' }} className="img-wrapper" alt="datavisualization" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">แพลตฟอร์มกลาง/ส่วนตัว</h4>
                                                    <div className="description">สามารถเลือกใช้บริการได้ทั้งแบบแพลตฟอร์มกลาง (Public Platform) แชร์ทรัพยากรร่วมกับผู้อื่น หรือแพลตฟอร์มส่วนตัว (Private Platform) สำหรับการใช้งานภายในองค์กร</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col span={24} className="card">
                                            <div className="inner-card">

                                                <img src={protocol} style={{ height: '160px' }} className="img-wrapper" alt="integration" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">รองรับอุปกรณ์ที่หลากหลาย</h4>
                                                    <div className="description">จากการออกแบบให้เชื่อมต่อแพลตฟอร์มตามมาตรฐานของ MQTT Protocol ทำให้รองรับอุปกรณ์ตั้งแต่ Microcontroller ขนาดเล็กไปจนถึง Embedded Linux และ Server และรองรับทุกโปรแกรมภาษา</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col span={24} className="card">
                                            <div className="inner-card">
                                                <img src={integration} style={{ height: '160px' }} className="img-wrapper" alt="integration" />
                                                <br></br>
                                                <br></br>
                                                <div className="text-wrapper">
                                                    <h4 className="is-text-align-center">พร้อมเชื่อมต่อระบบที่มีอยู่</h4>
                                                    <div className="description">รองรับการเชื่อมต่อไปยังระบบอื่นๆ ที่มีอยู่เดิม (3rd Party) ด้วย APIs ที่ถูกออกแบบไว้สำหรับหลากหลายรูปแบบ</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
            </div>
        );
    }
}