import React from 'react';
import { Row, Col, Button } from 'antd';



export default class Footer extends React.Component {
    render() {
        return (
            <span id="footer">
                <Col style={{ width: "100%" }} className="is-darkblue-bg" md={24} xs={0}>
                    <div className="is-footer is-page is-center" >
                        <Row className="is-center" style={{ width: "100%" }} >
                            <Col lg={17} md={14}>
                                <p className="is-text-align-left">Copyright © 2020 Created by NEXPIE Co., Ltd.</p>
                            </Col>
                            <Col lg={7} md={10}>
                                <Row className="is-center" style={{ width: "100%" }} type="flex" justify="end">
                                    <Col lg={11} md={11}>
                                        <Button size="large" className="is-button-portal" type="link" ghost><a href="https://nexpie.com/privacypolicy.pdf">Privacy Policy</a></Button>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="is-text-align-center" style={{ color: "#fff" }}>|</div>
                                    </Col>
                                    <Col lg={11} md={11}>
                                        <Button size="large" className="is-button-portal " type="link" ghost><a href="/termsofuse">Terms of Use</a></Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col style={{ width: "100%" }} className="is-darkblue-bg" xs={24} md={0}>
                    <div className="is-footer is-page is-center" >
                        <Row className="is-center" style={{ width: "100%" }} >
                            <Col xs={11}>
                                <Button size="large" className="is-button-portal" type="link" ghost><a href="https://nexpie.com/privacypolicy.pdf">Privacy Policy</a></Button>
                            </Col>
                            <Col xs={2}>
                                <div className="is-text-align-center" style={{ color: "#fff" }}>|</div>
                            </Col>
                            <Col xs={11}>
                                <Button size="large" className="is-button-portal " type="link" ghost><a href="/termsofuse">Terms of Use</a></Button>
                            </Col>

                        </Row>
                    </div>
                    <div className="is-footer is-page is-center" >
                        <Row className="is-center" style={{ width: "100%" }} >
                            <Col xs={24}>
                                <p style={{ fontSize: "14px" }} className="is-text-align-center">Copyright © 2020 Created by NEXPIE Co., Ltd.</p>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </span>
        );
    }
}
