import React from "react";
// import QueueAnim from 'rc-queue-anim';
import world from "../../assets/world.gif";
import { Row, Col, Button } from "antd";

export default class Banner extends React.Component {
  render() {
    return (
      <div id="banner">
        <Col className="is-blue-bg" xs={0} md={0} lg={0} xl={24}>
          <div key="banner" className="banner is-page" type="alpha" delay={150}>
            <Row className="is-center is-fullpage">
              <Col span={13}>
                <div
                  key="text"
                  type="bottom"
                  delay={300}
                  className="text-wrapper is-text-align-left"
                >
                  <div key="a">
                    <h1>NEXPIE IoT Platform</h1>
                    <p>Internet of Things Experts in Thailand</p>
                    <a
                      href="https://docs.nexpie.io/getting-started.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="large" shape="round" ghost>
                        GET STARTED
                      </Button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col span={11}>
                <div
                  className="img-wrapper is-text-align-left"
                  key="b"
                  type="right"
                  delay={300}
                >
                  <img
                    src={world}
                    style={{ width: "90%" }}
                    key="a"
                    alt="world"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="is-blue-bg" xs={0} md={0} xl={0} lg={24}>
          <div key="banner" className="banner is-page" type="alpha" delay={150}>
            <Row className="is-center is-fullpage-tablet">
              <br></br>
              <Col span={13}>
                <div
                  key="text"
                  type="bottom"
                  delay={300}
                  className="text-wrapper is-text-align-left"
                >
                  <div key="a">
                    <h2>NEXPIE IoT Platform</h2>
                    <p>Internet of Things Experts in Thailand</p>
                    <a
                      href="https://docs.nexpie.io/getting-started.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="large" shape="round" ghost>
                        GET STARTED
                      </Button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col span={11}>
                <div
                  className="img-wrapper is-text-align-left"
                  key="b"
                  type="right"
                  delay={300}
                >
                  <img
                    src={world}
                    style={{ width: "90%" }}
                    key="a"
                    alt="world"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="is-blue-bg" xs={0} xl={0} lg={0} md={24} key="mdCol">
          <div key="banner" className="banner is-page" type="alpha" delay={150}>
            <Row className="is-center is-fullpage-tablet">
              <br></br>
              <Col span={13}>
                <div
                  key="text"
                  type="bottom"
                  delay={300}
                  className="text-wrapper is-text-align-left"
                >
                  <div key="a">
                    <h3>NEXPIE IoT Platform</h3>
                    <p>Internet of Things Experts in Thailand</p>
                    <a
                      href="https://docs.nexpie.io/getting-started.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="large" shape="round" ghost>
                        GET STARTED
                      </Button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col span={11}>
                <div
                  className="img-wrapper is-text-align-left"
                  key="b"
                  type="right"
                  delay={300}
                >
                  <img
                    src={world}
                    style={{ width: "90%" }}
                    key="a"
                    alt="world"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="is-blue-bg" xs={24} md={0} lg={0} xl={0}>
          <br></br>
          <div key="banner" className="banner is-page" type="alpha" delay={150}>
            <Col span={24}>
              <div
                key="text"
                type="bottom"
                delay={300}
                className="text-wrapper is-text-align-center"
              >
                <div key="a">
                  <h4>NEXPIE IoT Platform</h4>
                  <p>Internet of Things Experts in Thailand</p>
                  <a
                    href="https://docs.nexpie.io/getting-started.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button size="large" shape="round" ghost >
                      GET STARTED
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
            <br></br>
            <Col span={24}>
              <div
                className="img-wrapper is-text-align-center"
                key="b"
                type="right"
                delay={300}
              >
                <img src={world} style={{ width: "90%" }} key="a" alt="world" />
                <br></br>
              </div>
            </Col>
          </div>
        </Col>
      </div>
    );
  }
}
