import React from 'react';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import startup from '../../assets/startup.gif';
import growth from '../../assets/growth.gif';
import scale from '../../assets/scale.gif';
import { Row, Col, Table } from 'antd';
const quotaLarge = [
    {
        title: (
            <div key="title1">
                <div className="text-wrapper is-header-card">
                    <h4 className="is-text-align-center">&nbsp;</h4>
                </div>
                {/* <img src={startup} style={{ height: '120px' }} className="img-wrapper" alt="startup" /> */}
                <div style={{ height: '120px' }} className="img-wrapper" ></div>
                <br></br>
                <br></br>
                <div className="text-wrapper">
                    <div className="is-text-align-center">&nbsp;</div>
                    <div className="unitprice is-text-align-center">&nbsp;</div>
                </div>
            </div>
        ),
        startup: (
            <div>
                <div className="text-wrapper is-header-card">
                    <h4 className="is-text-align-center">STARTUP</h4>
                </div>
                <img src={startup} style={{ height: '120px' }} className="img-wrapper" alt="startup" />
                <br></br>
                <br></br>
                <div className="text-wrapper">
                    <div className="price is-text-align-center">290</div>
                    <div className="unitprice is-text-align-center">บาท/เดือน</div>
                </div>
            </div>
        ),
        growth: (
            <div>
                <div className="text-wrapper is-header-card">
                    <h4 className="is-text-align-center">GROWTH</h4>
                </div>
                <img src={growth} style={{ height: '120px' }} className="img-wrapper" alt="growth" />
                <br></br>
                <br></br>
                <div className="text-wrapper">
                    <div className="price is-text-align-center">2,800</div>
                    <div className="unitprice is-text-align-center">บาท/เดือน</div>
                </div>
            </div>
        ),
        scale: (
            <div>
                <div className="text-wrapper is-header-card">
                    <h4 className="is-text-align-center">SCALE</h4>
                </div>
                <img src={scale} style={{ height: '120px' }} className="img-wrapper" alt="scale" />
                <br></br>
                <br></br>
                <div className="text-wrapper">
                    <div className="price is-text-align-center">13,000</div>
                    <div className="unitprice is-text-align-center">บาท/เดือน</div>
                </div>
            </div>
        ),
        suffix: ""
    },
    {
        title: (<span key="title1"><span style={{ color: "#05366c", fontSize: "16px" }}>Connected devices</span><br></br>(devices)</span>),
        startup: (<h5>10</h5>),
        growth: (<h5>100</h5>),
        scale: (<h5>500</h5>),
        suffix: "devices"
    },
    {
        title: (<span key="title2"><span style={{ color: "#05366c", fontSize: "16px" }}>Real-time messages</span><br></br>(messages{window.location.pathname === "/pricing" && <sup >[1]</sup>} per month)</span>),
        startup: (<h5>9,000,000</h5>),
        growth: (<h5>110,000,000</h5>),
        scale: (<h5>650,000,000</h5>),
        suffix: (
            <div>
                messages<sup >[1]</sup> per month
            </div>
        )
    },
    {
        title: (<span key="title3"><span style={{ color: "#05366c", fontSize: "16px" }}>Time-series data storage</span><br></br>(point-month{window.location.pathname === "/pricing" && <sup >[2]</sup>})</span>),
        startup: (<h5>1,000,000</h5>),
        growth: (<h5>20,000,000</h5>),
        scale: (<h5>130,000,000</h5>), suffix: (
            <div>
                point-month<sup>[2]</sup>
            </div>
        )
    },
    {
        title: (<span key="title4"><span style={{ color: "#05366c", fontSize: "16px" }}>Shadow read/write</span><br></br>(operations per month)</span>),
        startup: (<h5>500,000</h5>),
        growth: (<h5>8,000,000</h5>),
        scale: (<h5>50,000,000</h5>), suffix: "operations per month"
    },
    {
        title: (<span key="title5"><span style={{ color: "#05366c", fontSize: "16px" }}>API call</span><br></br>(operations per month)</span>),
        startup: (<h5>800,000</h5>),
        growth: (<h5>11,000,000</h5>),
        scale: (<h5>70,000,000</h5>), suffix: "operations per month"
    },
    {
        title: (<span key="title6"><span style={{ color: "#05366c", fontSize: "16px" }}>Trigger and action</span><br></br>(operations per month)</span>),
        startup: <h5>1,000,000</h5>,
        growth: <h5>15,000,000</h5>,
        scale: <h5>100,000,000</h5>,
        suffix: "operations per month"
    }
];
const quotaSmall = [
    {
        title: (<span><span style={{ color: "#05366c", fontSize: "16px" }}>Connected devices</span><br></br>(devices)</span>),
        startup: (<h5>10</h5>),
        growth: (<h5>100</h5>),
        scale: (<h5>500</h5>),
        suffix: "devices"
    },
    {
        title: (<span><span style={{ color: "#05366c", fontSize: "16px" }}>Real-time messages</span><br></br>(messages{window.location.pathname === "/pricing" && <sup >[1]</sup>} per month)</span>),
        startup: (<h5>9,000,000</h5>),
        growth: (<h5>110,000,000</h5>),
        scale: (<h5>650,000,000</h5>),
        suffix: (
            <div>
                messages<sup >[1]</sup> per month
            </div>
        )
    },
    {
        title: (<span><span style={{ color: "#05366c", fontSize: "16px" }}>Time-series data storage</span><br></br>(point-month{window.location.pathname === "/pricing" && <sup >[2]</sup>})</span>),
        startup: (<h5>1,000,000</h5>),
        growth: (<h5>20,000,000</h5>),
        scale: (<h5>130,000,000</h5>), suffix: (
            <div>
                point-month<sup>[2]</sup>
            </div>
        )
    },
    {
        title: (<span><span style={{ color: "#05366c", fontSize: "16px" }}>Shadow read/write</span><br></br>(operations per month)</span>),
        startup: (<h5>500,000</h5>),
        growth: (<h5>8,000,000</h5>),
        scale: (<h5>50,000,000</h5>), suffix: "operations per month"
    },
    {
        title: (<span><span style={{ color: "#05366c", fontSize: "16px" }}>API call</span><br></br>(operations per month)</span>),
        startup: (<h5>800,000</h5>),
        growth: (<h5>11,000,000</h5>),
        scale: (<h5>70,000,000</h5>), suffix: "operations per month"
    },
    {
        title: (<span><span style={{ color: "#05366c", fontSize: "16px" }}>Trigger and action</span><br></br>(operations per month)</span>),
        startup: (<h5>1,000,000</h5>),
        growth: (<h5>15,000,000</h5>),
        scale: (<h5>100,000,000</h5>),
        suffix: "operations per month"
    }
];
const columnsLarge = [
    {
        dataIndex: "title",
        render: title => <div style={{ textAlign: "right" }}>{title}</div>,
        key: 'title',
        width: 220,
    },
    {
        dataIndex: "startup",
        render: startup => <div style={{ textAlign: "center" }}>{startup}</div>,
        key: 'startup',

    },
    {
        dataIndex: "growth",
        render: growth => <div style={{ textAlign: "center" }}>{growth}</div>,
        key: 'growth',

    },
    {
        dataIndex: "scale",
        render: scale => <div style={{ textAlign: "center" }}>{scale}</div>,
        key: 'scale',

    }
];
const columnsSmall1 = [
    {
        title: (<div style={{ textAlign: "right" }}> <div>
            <div className="text-wrapper is-header-card">
                <h4 className="is-text-align-center">STARTUP</h4>
            </div>
            <img src={startup} style={{ height: '120px' }} className="img-wrapper" alt="startup" />
            <br></br>
            <br></br>
            <div className="text-wrapper">
                <div className="price is-text-align-center">290</div>
                <div className="unitprice is-text-align-center">บาท/เดือน</div>
            </div>
        </div></div>),

        children: [{
            dataIndex: 'title',
            key: 'titleSmall1',
            width: 220,
        }, {
            dataIndex: 'startup',
            render: startup => <div style={{ textAlign: "center" }}>{startup}</div>,
            key: 'startupSmall1',
        }]
    }
];
const columnsSmall2 = [
    {
        title: (<div style={{ textAlign: "right" }}> <div>
            <div className="text-wrapper is-header-card">
                <h4 className="is-text-align-center">GROWTH</h4>
            </div>
            <img src={growth} style={{ height: '120px' }} className="img-wrapper" alt="growth" />
            <br></br>
            <br></br>
            <div className="text-wrapper">
                <div className="price is-text-align-center">2,800</div>
                <div className="unitprice is-text-align-center">บาท/เดือน</div>
            </div>
        </div></div>),

        children: [{
            dataIndex: 'title',
            key: 'titleSmall2',
            width: 220,
        }, {
            dataIndex: 'growth',
            render: growth => <div style={{ textAlign: "center" }}>{growth}</div>,
            key: 'growthSmall2',
        }]
    }
];
const columnsSmall3 = [
    {
        title: (<div style={{ textAlign: "right" }}> <div>
            <div className="text-wrapper is-header-card">
                <h4 className="is-text-align-center">SCALE</h4>
            </div>
            <img src={scale} style={{ height: '120px' }} className="img-wrapper" alt="scale" />
            <br></br>
            <br></br>
            <div className="text-wrapper">
                <div className="price is-text-align-center">13,000</div>
                <div className="unitprice is-text-align-center">บาท/เดือน</div>
            </div>
        </div></div>),

        children: [{
            dataIndex: 'title',
            key: 'titleSmall3',
            width: 220,
            // foo: ti
        }, {
            dataIndex: 'scale',
            key: 'scaleSmall3',
            render: scale => <div style={{ textAlign: "center" }}>{scale}</div>,
            // foo: foo
        }]
    }
];


export default class Package extends React.Component {
    render() {
        const { isMarginTop } = this.props;
        return (
            <div id="package" >
                <Col className="is-lightblue-bg" xs={0} md={24} lg={24} xl={24}>
                    <div className="package is-page" style={{ marginTop: isMarginTop }}>
                        <div className="is-fullpage">
                            {/*  playScale="0.1">*/}
                            <div key="package" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="a">ราคา NEXPIE IoT Platform</h3>
                                        <p key="b">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center">
                                    <Col span={24} >
                                        <div
                                            key="text"
                                            type="bottom"
                                            delay={300}
                                        >
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <Table
                                                    columns={columnsLarge}
                                                    dataSource={quotaLarge}
                                                    pagination={false}
                                                    showHeader={false}
                                                    bordered
                                                    style={{ margin: "1em 0" }}
                                                    size="small"
                                                    rowKey="title"
                                                />
                                            </Col>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
                <Col className="is-lightblue-bg" xs={24} md={0} lg={0} xl={0}>
                    <div className="package is-page" style={{ marginTop: isMarginTop }}>
                        <div className="is-fullpage">
                            {/*  playScale="0.1">*/}
                            <div key="package" type="alpha" delay={150}>
                                <Row className="is-center">
                                    <Col span={24} className="text-wrapper">
                                        <h3 key="d">ราคา NEXPIE IoT Platform</h3>
                                        <p key="e">NEXPIE IoT Platform จะเป็นเครื่องมือทุ่นแรงช่วยให้องค์กรของคุณพัฒนา Internet of Things (IoT) ได้รวดเร็วและมีเสถียรภาพ</p>
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center">
                                    <Col span={24} >
                                        <div
                                            key="text"
                                            type="bottom"
                                            delay={300}
                                        >
                                            <Col span={24} key="f" style={{ textAlign: "center" }}>
                                                <Table
                                                    columns={columnsSmall1}
                                                    dataSource={quotaSmall}
                                                    pagination={false}
                                                    showHeader={true}
                                                    bordered
                                                    style={{ margin: "1em 0" }}
                                                    size="small"
                                                    rowKey="columnsSmall1"
                                                />
                                            </Col>

                                        </div>
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center">
                                    <Col span={24} >
                                        <div
                                            key="text"
                                            type="bottom"
                                            delay={300}
                                        >
                                            <Col span={24} key="g" style={{ textAlign: "center" }}>
                                                <Table
                                                    columns={columnsSmall2}
                                                    dataSource={quotaSmall}
                                                    pagination={false}
                                                    showHeader={true}
                                                    bordered
                                                    style={{ margin: "1em 0" }}
                                                    size="small"
                                                    rowKey="columnsSmall2"
                                                />
                                            </Col>

                                        </div>
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center">
                                    <Col span={24} >
                                        <div
                                            key="text"
                                            type="bottom"
                                            delay={300}
                                        >
                                            <Col span={24} key="h" style={{ textAlign: "center" }}>
                                                <Table
                                                    columns={columnsSmall3}
                                                    dataSource={quotaSmall}
                                                    pagination={false}
                                                    showHeader={true}
                                                    bordered
                                                    style={{ margin: "1em 0" }}
                                                    size="small"
                                                    rowKey="columnsSmall3"
                                                />
                                            </Col>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {/* </ScrollOverPack> */}
                        </div>
                    </div >
                </Col >
            </div>
        );
    }
}