import React, { Component } from "react";
import Contact from "../components/utils/Contact";
import { enquireScreen } from "enquire-js";
import Page from "../components/Page";
import Backtotop from "../components/utils/Backtotop";
import { Row, Col, Button } from "antd";
import nexpie from "../assets/NEXPIE-Name-Icon.png";
import netpie from "../assets/netpie-logo.png";
import dashboard from "../assets/dashboard.png";
import flow from "../assets/flow.png";
import ruleengine from "../assets/ruleengine.png";
import alert from "../assets/alert.png";
import flowengine_node from "../assets/flowengine_node.png";
import flowengine_dashboard from "../assets/flowengine_dashboard.png";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});
class Flowengine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }

  render() {
    return (
      <>
        <Page>
          <div id="flowengine" className="is-white-bg">
            <div className="product is-page">
              <div className="is-fullpage ">
                <Row className="is-center">
                  <Col span={24} className="text-wrapper">
                    <Col
                      xs={0}
                      md={2}
                      lg={2}
                      xl={2}
                      className="text-wrapper"
                    ></Col>
                    <Col
                      xs={24}
                      md={20}
                      lg={20}
                      xl={20}
                      className="text-wrapper"
                    >
                      <h3>Flow Engine</h3>
                      <br></br>

                      <Row className="is-center">
                        <Col
                          xs={0}
                          md={0}
                          lg={24}
                          xl={24}
                          className="text-wrapper"
                        >
                          <Col span={12} className="text-wrapper">
                            <div style={{ paddingRight: "11px" }}>
                              <img
                                className="img-border"
                                src={flowengine_node}
                                style={{ width: "100%" }}
                                key="flowengine_node"
                                alt="flowengine_node"
                              />
                            </div>
                          </Col>
                          <Col span={12} className="text-wrapper">
                            <div style={{ paddingLeft: "11px" }}>
                              <img
                                className="img-border"
                                src={flowengine_dashboard}
                                style={{ width: "100%" }}
                                key="flowengine_dashboard"
                                alt="flowengine_dashboard"
                              />
                            </div>
                          </Col>
                        </Col>
                        <Col
                          xs={24}
                          md={24}
                          lg={0}
                          xl={0}
                          className="text-wrapper"
                        >
                          <Col span={24} className="text-wrapper">
                            <div style={{ paddingBottom: "11px" }}>
                              <img
                                className="img-border"
                                src={flowengine_node}
                                style={{ width: "100%" }}
                                key="flowengine_node"
                                alt="flowengine_node"
                              />
                            </div>
                          </Col>
                          <br></br>
                          <Col span={24} className="text-wrapper">
                            <div style={{ paddingTop: "11px" }}>
                              <img
                                className="img-border"
                                src={flowengine_dashboard}
                                style={{ width: "100%" }}
                                key="flowengine_dashboard"
                                alt="flowengine_dashboard"
                              />
                            </div>
                          </Col>
                        </Col>
                      </Row>
                      <br></br>
                      <br></br>
                      <h4>Overview</h4>
                      {/* <p>
                        เปลี่ยนการเขียนโปรแกรม IoT
                        ให้เป็นเรื่องง่ายโดยไม่ต้องเรียนรู้ภาษาใด ๆ เพิ่มเติม
                      </p> */}
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Flow Engine คือ
                        ซอฟต์แวร์สําหรับใช้เป็นเครื่องมือเสริม
                        ช่วยให้การปรับแต่งระบบ IoT ที่พัฒนาสะดวกยิ่งขึ้น
                        โดยการใช้งานจะเป็นลักษณะของการเชื่อมต่อโหนด
                        การทํางานแต่ละส่วนเข้าด้วยกัน มักถูกเรียกว่า Flow
                        Programming เนื่องจาก
                        ฟังก์ชันแต่ละฟังก์ชันถูกบรรจุมาให้อยู่ในลักษณะของกล่องสี่เหลี่ยมหรือโหนด
                        ที่สามารถลากเชื่อมต่อกันได้
                        โดยการทํางานนั้นมีลักษณะคล้ายคลึงกับแผนภูมิ การไหล
                        (Flowchart)
                        คือจะเริ่มทํางานจากโหนดต้นทางและส่งค่าข้อมูลที่ประมวลผล
                        ต่อให้กับโหนดถัดไปที่ถูกเชื่อมโยงกันจากโหนดซ้ายไปโหนดขวา
                      </p>
                      <br></br>
                      <h4>Documentation</h4>
                      <Button
                        className="color-button-primary"
                        type="primary"
                        icon="file-text"
                        href="https://archive.nexpie.io/flowengine/manuals/flowengine_20210327.pdf"
                        target="_blank"
                      >
                        DOCUMENT
                      </Button>
                      <br></br>
                      <br></br>
                      <br></br>
                      <h4>Benefit</h4>
                      <ul>
                        <li>
                          <p>
                            พร้อมเชื่อมต่อไปยังแพลตฟอร์ม{" "}
                            <a
                              href="https://nexpie.io"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={nexpie}
                                style={{ height: "14px" }}
                                key="nx"
                                alt="nexpie"
                              />
                            </a>{" "}
                            และ{" "}
                            <a
                              href="https://netpie.io"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={netpie}
                                style={{ height: "30px" }}
                                key="np"
                                alt="netpie"
                              />
                            </a>
                          </p>
                        </li>
                        <li>
                          <p>เขียนโปรแกรมอย่างง่ายด้วยวิธี ‘ลาก-วาง’</p>
                        </li>
                        <li>
                          <p>
                            สร้างหน้าแดชบอร์ดอย่างง่ายเพื่อสร้าง-แสดงผลข้อมูลต่าง
                            ๆ
                          </p>
                        </li>
                      </ul>
                      <br></br>
                      <br></br>
                      <h4>Feature</h4>
                      <ul>
                        <li>
                          <p>
                            <img
                              src={flow}
                              style={{ width: "32px" }}
                              key="flow"
                              alt="flow"
                            />{" "}
                            Flow Programming : การเขียนโปรแกรมโดยเชื่อมต่อโหนด
                            การทํางานแต่ละส่วนเข้าด้วยกัน
                          </p>
                        </li>
                        <li>
                          <p>
                            <img
                              src={alert}
                              style={{ width: "32px" }}
                              key="alert"
                              alt="alert"
                            />{" "}
                            Alert : การแจ้งเตือนเหตุการณ์ต่างๆ ไปยัง Line,
                            Onechat
                          </p>
                        </li>
                        <li>
                          <p>
                            <img
                              src={dashboard}
                              style={{ width: "32px" }}
                              key="dashboard"
                              alt="dashboard"
                            />{" "}
                            Local Dashboard : แสดงข้อมูลและควบคุมการทํางานแบบ
                            Real-time
                          </p>
                        </li>
                        <li>
                          <p>
                            <img
                              src={ruleengine}
                              style={{ width: "32px" }}
                              key="ruleengine"
                              alt="ruleengine"
                            />{" "}
                            Rule Engine : การสร้าง Logic
                            พิเศษสําหรับการทํางานแบบ Automation
                          </p>
                        </li>
                      </ul>
                    </Col>

                    <Col
                      xs={0}
                      md={2}
                      lg={0}
                      xl={0}
                      className="text-wrapper"
                    ></Col>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Contact isMobile={this.state.isMobile} />
          <Backtotop />
        </Page>
      </>
    );
  }
}
export default Flowengine;
