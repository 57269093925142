import React, { Component } from 'react';
import { enquireScreen } from 'enquire-js';
import Page from "../components/Page";
import Backtotop from '../components/utils/Backtotop';
import Contact from '../components/utils/Contact';

import { Result, Button } from 'antd';

let isMobile = false;
enquireScreen((b) => {
    isMobile = b;
});
class Notfound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b,
            });
        });
    }

    render() {
        return (
            <>
                <Page>
                    <div id="notfound" className="is-white-bg">
                        <div className="notfound is-page" >
                            <div className="is-fullpage is-center">
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle="Sorry, the page you visited does not exist."
                                    extra={

                                        <Button size="large" href="/" shape="round" className="is-text-align-center" type="primary">                                            BACK HOME
                                          </Button>
                                    }
                                    style={{ margin: "auto" }}
                                />
                            </div>
                        </div>
                    </div>
                    <Contact isMobile={this.state.isMobile} />
                    <Backtotop />
                </Page>
            </>
        );
    }
}
export default Notfound;