import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Flowengine from "./pages/Flowengine";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";
import PIEBOX1steditionseries from "./pages/PIEBOX1steditionseries_th";
import Redeemtermofuse from "./pages/Redeemtermofuse";
import Termsofuse from "./pages/Termsofuse";
import "./styles/style.scss";

function App() {
  return (
    <div id="app" className="App">
      <Helmet>
        <title>NEXPIE IoT Platform</title>
        <link rel="image_src" href="./assets/favicon.png" />
        <meta property="og:url" content="https://nexpie.io" />
        <meta property="og:image" content="./assets/favicon.png" />
        <meta
          name="description"
          content="NEXPIE IoT Platform | Internet of Things Experts in Thailand"
        />
        <meta
          name="keywords"
          content="NEXPIE, NEXPIE IoT, IoT, Internet of Things, IoT Platform, IoT Thai, IoT Thailand, IoT Cloud, IoT PaaS, paas, platform-as-a-service, IoT backend, ESP8266, Arduino, cloud platform, Internet, Everything, Thing, Platform, MQTT, IoT Application, IoT Course, IoT Project, IoT Software, IoT Training, IoT Tutorial, IoT Consulting, IoT Video, IoT Dashboard, IoT Service, IoT Manual, IoT Library, IoT  DIY, Freeboard, IoT Feed"
        />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content="NEXPIE IoT Platform" />
        <meta
          property="og:description"
          content="NEXPIE IoT Platform | Internet of Things Experts in Thailand"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Prompt&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/termsofuse" component={Termsofuse} />
          <Route exact path="/redeem-term" component={Redeemtermofuse} />
          <Route exact path="/piebox" component={PIEBOX1steditionseries} />
          <Route exact path="/flowengine" component={Flowengine} />
          <Route component={Notfound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
