import React from 'react';
import { BackTop, Button } from 'antd';

export default class Backtotop extends React.Component {
    render() {
        return (
            <div>
                <BackTop>
                    <div className="ant-back-top-inner"><Button size="large" type="primary" shape="circle" icon="caret-up" /></div>
                </BackTop>
            </div>
        );
    }
}